import React from 'react';
import Modal from 'react-modal';
import { MdCheck, MdOutlineWarningAmber, MdClose } from 'react-icons/md';

export default function NotificationModal({
  isOpen = true,
  title,
  text,
  isMobile,
  textClass,
  icon = 'warning',
  style = {
    content: {
      maxWidth: 400,
      maxHeight: 320,
      borderRadius: 10,
      // margin: "auto",
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  actionButtons = false,
  successModal,
  closeModal = () => { },
  successLabel = 'Confirmar',
  closeLabel = 'Cancelar',
  children,
  confirmButtonClass,
  cancelButtonClass
}) {
  const iconType = (type) => {
    const icons = {
      success: <MdCheck color='#65A503' />,
      warning: <MdOutlineWarningAmber color='#E4B214' />,
      error: <MdClose color='#ff4a36' />,
    };
    return icons[type]
  };

  const handleClose = () => {
    closeModal()
  };

  const handleSuccess = () => {
    if (successModal) successModal()
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={style}
    >
      <div className="relative overflow-hidden overflow-y-auto h-full flex flex-col items-center gap-1 inset-0">
        <div className="text-5xl">
          {iconType(icon)}
        </div>
        {title && (
          <div className={`w-full flex flex-col ${actionButtons === 'one' ? 'items-end' : 'items-center'}  h-full ${children ? 'gap-[10px]' : ''}`}>
            <h1 className={`w-full text-xl leading-6 font-semibold text-center text-dark-gray`}>{title}</h1>
            {children || (
              <div className={`flex flex-col gap-1 ${textClass}`}>
                <p className="text-base text-center text-[#4F4F4F]">{text}</p>
              </div>
            )}
            {actionButtons && (
              <div className={`${!isMobile ? 'flex gap-x-5 mt-10' : 'w-full mt-3 flex gap-x-3 text-[14px] leading-[16px] justify-center'}`}>
                {actionButtons === 'one' ? (
                  <button onClick={handleClose} className={`btn btn-primary text-secondary px-7 py-3 `}>
                    {closeLabel}
                  </button>
                ) : (
                  <>
                    <button onClick={handleClose} className={`${confirmButtonClass} ${isMobile ? 'btn btn-secondary text-primary p-6 py-5' : 'btn btn-secondary text-primary py-4 gap-3'}`}>
                      {closeLabel}
                    </button>
                    <button onClick={handleSuccess} className={`${cancelButtonClass} btn btn-primary text-secondary ${successLabel.length > 9 && 'p-7 py-4 w-full'}`}>
                      {successLabel}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
